import React from 'react';
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import { withMDXComponents } from "@mdx-js/tag/dist/mdx-provider";
import styled from 'styled-components';

import Content from './content';
import Hero from './hero';
import Layout from './layout';
import SEO from './seo';

const Article = styled.article`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

function BlogPost({ data }) {
  const post = data.mdx;
  const { date, hero, social, title } = post.frontmatter;

  return (
    <Layout>
      <SEO title={title} image={social.childImageSharp.fixed} type="article" />

      <Article>
        <Hero
          date={date}
          image={hero}
          title={title}
        />

        <Content>
          <MDXRenderer>{data.mdx.code.body}</MDXRenderer>
        </Content>
      </Article>
    </Layout>
  );
}

export default withMDXComponents(BlogPost);

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      code {
        body
      }

      frontmatter {
        title
        date(formatString: "D MMMM YYYY")

        social: hero {
          childImageSharp {
            fixed(
              duotone: {
                opacity: 75
                highlight: "#FED400"
                shadow: "#FF022C"
              }
              cropFocus: CENTER
              height: 630
              width: 1200
            ) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        hero {
          childImageSharp {
            fluid(
              duotone: {
                opacity: 75
                highlight: "#FED400"
                shadow: "#FF022C"
              }
              traceSVG: {
                background: "#FF741F"
                color: "#FF022C"
                turnPolicy: TURNPOLICY_MINORITY
              }
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
